<template>
  <div></div>
</template>

<script>
export default {
  name: "HorizontalDivider",
};
</script>

<style scoped>
div {
  width: 100%;
  height: 3px;
  background-color: #1b405e;
  margin: 2rem 0;
}
</style>
